import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer className=" footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-12 footer-list">
                            <img className="logo-foot" src="assets/images/logo-white.png" />
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard.Lorem Ipsum is
                                simply dummy text.
                            </p>
                        </div>
                        <div className="col-md-3 col-12 footer-list">
                            <h4 className="mt-2 font-weight-bold">Quick Links</h4>
                            <ul>
                                <li>
                                    <Link to="/aboutUs"> 
                                        About us
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/properties"> 
                                        Our Projects
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="/contactUs"> 
                                        Contact Us
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/properties"> 
                                        Properties
                                    </Link>
                                </li>
 
                            </ul>
                        </div>
                        <div className="col-md-3 col-12 footer-list">
                            <h4 className="mt-2 font-weight-bold">Our Policies</h4>
                            <ul>
                                <li>
                                    <a href="#">Terms &amp; Condition</a>
                                </li>
                                <li>
                                    <a href="#">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-12 footer-list">
                            <h4 className="mt-2 font-weight-bold">Stay up to date</h4>
                            <p>Stay updated with Ellington news, zero spam, we promise 🙏</p>
                            <ul className="social-links">
                                <li>
                                    <a href="">
                                        <img src="assets/images/social1.png" />
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        <img src="assets/images/social2.png" />
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        <img src="assets/images/social3.png" />
                                    </a>
                                </li>
                            </ul>
                            <div className=" copyright-line mt-0">
                                <p>
                                    2022 <b>Ellington Properties</b>.All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer