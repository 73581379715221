import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SuperSearch } from '../../components/SuperSearch';
import { SuperSort } from '../../components/SuperSort';
import axiosMain from '../../https/axios/axiosMain';
import PageLayout from '../../PageLayout/PageLayout'
import { fetchTransactions } from '../../reducers/userReducer';



function Transaction() {
	const dispatch = useDispatch();
	const { isLoading, transactions, error } = useSelector((state) => state.user)
	const { value: { user, isAuthenticated }, walletAddress } = useSelector((state) => state.auth);

	//===================Local States================//
	const [filteredData, setFilteredData] = useState(transactions);


	useEffect(() => {
		// getAllTransactions();
		dispatch(fetchTransactions(walletAddress))
	}, [walletAddress]);

	console.log(transactions, error, walletAddress);
	//==================Functions=================//
	//To fetch all transactions
	// const getAllTransactions = async () => {
	// 	try {
	// 		const url = "/transaction";
	// 		const payload = {
	// 			wallet: "0xd3A5B4cfDDF26945E0BbEf28CF32760b96f758C6"
	// 		};
	// 		const res = await axiosMain.post(url, payload);

	// 		console.log(res);

	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// }
	return (
		<>
			<PageLayout>
				<section className="main-banner about-banner project-sec-inner transaction-page">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-sm-12 m-auto transaction-inner">
								<h2 className="main-heading-transaction">Transaction List</h2>
								<div className="row">
									<div className="col-sm-12">
										<div>
											<div className="row">
												<div className="col-md-5">
													<div>
														<div className="search-field">
															<div className="input-group">
																<div className="input-group-prepend bg-transparent">
																	<i
																		className="input-group-text  fa fa-search"
																		aria-hidden="true"
																	/>
																</div>
																<SuperSearch dataSource={transactions} getfilteredData={(e) => setFilteredData(e)} isLoading={(e) => { }} />
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-3">
													<div>
														<div className="search-field">
															<div className="input-group">
																<div className="input-group-prepend bg-transparent">
																	<div className="input-group-text ">Filter</div>
																</div>
																<SuperSort field={{ "A_Z": "propertyName", "Z_A": "propertyName", "NEWEST": "createdAt", "OLDEST": "createdAt" }} dataSource={transactions} getSortedData={(e) => setFilteredData(e)} isLoading={() => { }} />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table table-striped table-bordered table-transaction">
												<thead>
													<tr>
														<th width="8%">Image</th>
														<th width="14%">Plot Type</th>
														<th width="8%">Amount</th>
														<th width="20%">Payment hash</th>
														<th width="20%">Tokenid</th>
														<th width="20%">Wallet address From</th>
														<th width="20%">Wallet address To</th>
														<th width="10%">Status</th>
													</tr>
												</thead>
												<tbody>
													{!isLoading && filteredData?.map((item, index) => {
														return (
															
															<tr key={item?._id}>
																<td>
																	<img
																		width={50}
																		src={item?.image || "assets/images/service_img.png"}
																		alt=""
																	/>
																</td>
																<td>{item?.propertyName}</td>
																<td>{item?.amount}</td>
																<td><a
																	href={`https://testnet.bscscan.com/tx/${item.hash}`}
																	target="_blank"
																>{item?.hash}</a></td>
																<td>{item?.tokenid}</td>

																<td><a
																	href={`https://testnet.bscscan.com/address/${item.fromAddress}`}
																	target="_blank"
																>{item?.fromAddress}</a></td>
																<td><a
																	href={`https://testnet.bscscan.com/address/${item.toAddress}`}
																	target="_blank"
																	>{item?.toAddress}</a></td>
																<td>
																	<span className={`badge ${item.status === "Success" ? "badge-success" : "badge-warning"}`}>{item?.status}</span>
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
											
											{isLoading && <div className="form-control mb-5" >Please wait while we load transactions....</div>}
											{!isLoading && transactions.length < 1 && <div className="form-control mb-5" >No transactions found!!</div>}
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageLayout>
		</>
	)
}

export default Transaction
