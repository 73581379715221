import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Connect, onDisconnect } from "../helpers/Connect";
import { logout, setWalletAddress } from "../reducers/authReducers"
import { AddAssets, Addnetwork, _switch } from "../helpers/Web3Helper";

function Header() {

  const dispatch = useDispatch();
  const {
    value: { user, isAuthenticated },
    walletAddress,
  } = useSelector((state) => state.auth);
  const [UserAddress, setAddress] = useState(walletAddress || "");
  const [Chain, setChainId] = useState(0);

  const getAddress = async () => {
    let Details = await Connect();
    let chain = Details.chainId;
    setChainId(chain);
    setAddress(Details.selectedAccount);
  };

  useEffect(() => {
    if (walletAddress) {
      getAddress();
    }
  }, [walletAddress, Chain]);

  useEffect(() => {
    if (walletAddress) {
      getAddress();
    }
  }, []);

  console.log({ walletAddress })
  return (
    <>
      <header className="header-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg">
                <div className="navbar-brand justify-content-center">
                  <a href="/">
                    <img src="assets/images/logo-white.png" width="100%" />
                  </a>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    {/* <i className="fa fa-bars" /> */}
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse all-about-row"
                  id="navbarNavAltMarkup"
                >
                  <ul className="navbar-nav  m-auto">
                    <li className="nav-item active">
                      <Link to="/" className="linkheader">
                        <span className="nav-link">
                          Home
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item active">
                      <Link to="/aboutUs" className="linkheader">
                        <span className="nav-link" >
                          About
                        </span>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="/our-project" className="linkheader">
                        <a className="nav-link" href="#">
                          Our Projects
                        </a>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/properties" className="linkheader">
                        <span className="nav-link" >
                          Properties
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contactUs" className="linkheader">
                        <span className="nav-link" >
                          Contact Us
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/transaction" className="linkheader">
                        <span className="nav-link" >
                          Transaction
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="navbar-nav buy-now-row">
                    <li className="nav-item">
                      <Link className="nav-link login-btn" to="/properties">
                        Buy Now
                      </Link>
                    </li>

                    <li className="nav-item">
                      {/* connect wallet code start */}

                      {!UserAddress ? (
                        <button
                          className="nav-link login-btn"
                          onClick={async () => {
                            if (!UserAddress) {
                              let Details = await Connect();
                              setAddress(Details.selectedAccount);
                              setChainId(Details.chainId);
                              dispatch(
                                setWalletAddress(Details.selectedAccount)
                              );
                            }
                          }}
                        >
                          Connect Wallet
                        </button>
                      ) : Chain !== 97 ? (
                        <a
                          onClick={() => {
                            _switch();
                          }}
                          className="nav-link login-btn"
                        >
                          Wrong Network
                        </a>
                      ) : (
                        <a
                          href={`https://testnet.bscscan.com/address/${UserAddress}`}
                          target="_blank"
                          style={{ color: "#fff" }}
                          className="nav-link login-btn"
                        >
                          {UserAddress.replace(
                            UserAddress.substring(4, 36),
                            "..."
                          )}
                        </a>
                      )}

                      {/* connect wallet code end */}
                    </li>

                    {/* <li className=" nav-item dropdown user-dash-icon">
                                            <button
                                                className=" dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <img src="assets/images/meta_mask.png" />
                                            </button>
                                            <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <a className="dropdown-item" href="#">
                                                    Dashboard
                                                </a>
                                                <a className="dropdown-item" href="#">
                                                    Logout
                                                </a>
                                            </div>
                                        </li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
