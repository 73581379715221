import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Col,
  Row,
  NavDropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Connect } from "../../helpers/Connect";
import { _switch } from "../../helpers/Web3Helper";
import { logout, setWalletAddress } from "../../reducers/authReducers";

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        value: { user, isAuthenticated },
        walletAddress,
    } = useSelector((state) => state.auth);
    const [UserAddress, setAddress] = useState(walletAddress || "");
    const [Chain, setChainId] = useState(0);

    const getAddress = async () => {
        let Details = await Connect();
        let chain = Details.chainId;
        setChainId(chain);
        setAddress(Details.selectedAccount);
    };

    useEffect(() => {
        if (UserAddress) {
            getAddress();
        }
    }, [UserAddress, Chain]);

    useEffect(() => {
        if (UserAddress) {
            getAddress();
        }
    }, []);

    const handleLogout = () => {
        dispatch(logout());
        setTimeout(() => {
            navigate('/')
        })
    }

  return (
    <>
      <header className="main-header login-header-home">
        <Navbar expand="lg" sticky="top">
          <Container fluid>
            <Row className="w-100 m-auto">
              <Col>
                <div className="d-flex justify-content-between align-items-center">
                  <Navbar.Brand to="/">
                    <img src="assets/images/logo-white.png" />
                  </Navbar.Brand>

                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Link className="nav-link" to="/admin-homepage">
                        <i className="fa fa-th-large"></i> Dashboard
                      </Link>
                      <Link className="nav-link" to="/admin-landlist">
                        <i className="fa fa-money"></i>LandList
                      </Link>
                      <Link className="nav-link" to="/admin-transaction">
                        <i className="fa fa-exchange"></i> Transactions
                      </Link>
                      <Link className="nav-link" to="/admin-addnew-land">
                        <i className="fa fa-user"></i> Add New Land
                      </Link>

                      <Link className="nav-link" to="" onClick={handleLogout}>
                        <i className="fa fa-sign-out"></i> Logout
                      </Link>
                    </Nav>
                  </Navbar.Collapse>

                  {/* <Link to='/' className="ms-auto profile-dropdown"><img className="mr-2" src="assets/images/pass.png" /> GTH4536675...</Link> */}
                  {/* <Link to='/' className="border-btn">Connect Wallet</Link> */}
                  {!UserAddress ? (
                    <button
                      className="border-btn"
                      onClick={async () => {
                        if (!UserAddress) {
                          let Details = await Connect();
                          setAddress(Details.selectedAccount);
                          setChainId(Details.chainId);
                          dispatch(setWalletAddress(Details.selectedAccount));
                        }
                      }}
                    >
                      Connect Wallet
                    </button>
                  ) : Chain !== 97 ? (
                    <button
                      onClick={() => {
                        _switch();
                      }}
                      className="border-btn"
                    >
                      Wrong Network
                    </button>
                  ) : (
                    <a
                      href={`https://testnet.bscscan.com/address/${UserAddress}`}
                      target="_blank"
                      className="border-btn"
                    >
                      {UserAddress.replace(UserAddress.substring(4, 36), "...")}
                    </a>
                  )}
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
}
