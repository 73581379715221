import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom'
import { axios } from '../../https';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../../https/axios/axiosMain';
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../reducers/authReducers";


function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validationSchema = Yup.object({
        email: Yup.string().email().typeError("Enter valid Email").required('Email id is required'),
        password: Yup.string()
            .required("Password is required"),
    });
    const submitBtnHandler = async (values) => {
        console.log(values)
        try {
            const response = await axios.post('/login', values)
            console.log(response)
            console.log("data", response.data.data.loginObj);

            if (response.data.status) {
                toast.success(response.data.message, {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                })
                dispatch(
                    login({
                      user: response.data.data.loginObj,
                      isAuthenticated: true,
                    })
                  );
                setTimeout(() => {
                    navigate('/admin-homepage')

                }, 2000)
            }
        } catch (error) {
            toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }

    };

    return (
        <React.Fragment>
            <section className="login-sec">
                <Container >
                    <Row>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Card className="m-3">
                                <Card.Img className="login-logo" variant="top" src={"assets/images/logo.png"} />
                                <Card.Body className="pt-2">
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, { setSubmitting }) => {
                                            submitBtnHandler(values);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            // isSubmitting,
                                        }) => (

                                            <Form onSubmit={handleSubmit}>
                                                <div className="text-center">
                                                    <h5>Hello! let's get started</h5>
                                                    <p className='mt--5'>Sign in to continue</p>
                                                </div>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Group>
                                                <div className="error-message">
                                                    {errors.email && touched.email && errors.email}
                                                </div>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Password"
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Group>
                                                <div className="error-message">
                                                    {errors.password && touched.password && errors.password}
                                                </div>
                                                <Button className="common-btn w-100" type="submit">
                                                    <span>SIGN IN</span>
                                                </Button>
                                                <Form.Group className="mt-2 d-flex justify-content-between d-none" controlId="formBasicCheckbox">
                                                    <Form.Check type="checkbox" label="Keep me signed in" />
                                                    <p style={{ fontSize: '14px' }}>Forget password?</p>
                                                </Form.Group>
                                            </Form>
                                        )}
                                    </Formik>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ToastContainer />
        </React.Fragment >
    )
}

export default Login