import React from 'react';
import Footer from "./Footer";
import Header from "./Header";

function PageLayout(props) {
    return (
        <div className="custom">
            <Header />
            {props.children}
            <Footer />
        </div>
    )
}

export default PageLayout