import React from 'react'
import PageLayout from '../../PageLayout/PageLayout'

function OurProject() {
    return (
        <>
            <PageLayout>
                <section className="main-banner about-banner project-sec-inner">
                    <img src="assets/images/project/banner-img.png" />
                    <div className="about-banner-box">
                        <h2>WE BUILD RESIDENCES FOR PEOPLE WHO TRULY APPRECIATE DESIGN</h2>
                        <h6 className="sub-heading-inner">
                            FROM THE FIRST PRINCIPLES TO THE LAST DETAIL
                        </h6>
                    </div>
                </section>
                <section className="about-us-sec our-project-new">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="heading-main mb-5 text-center">Our Prperties </h3>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="project-img">
                                    <img src="assets/images/project/pj1.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="heading-main">Wilton park residences </h3>
                                <h6 className="sub-heading-inner gray-color">
                                    NATURE AT YOUR DOORSTEP
                                </h6>
                                <p>
                                    The ideal residential getaway for those who love nature, value
                                    tranquility and privacy, yet desire to be within close distance to
                                    many of Dubai’s world-class attractions, landmarks and transport
                                    links. Offering a true urban oasis in the heart of Dubai...
                                </p>
                                <div className="heading-box">
                                    <h5>
                                        Pent House <small>A 40*30 map</small>
                                    </h5>
                                    <h5>
                                        <small>(7 plots Left)</small> 1 Matic{" "}
                                    </h5>
                                </div>
                                <div>
                                    <a href="" className="common-btn">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-md-6 order-2">
                                <div className="project-img">
                                    <img src="assets/images/project/pj1.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="heading-main">Wilton park residences </h3>
                                <h6 className="sub-heading-inner gray-color">
                                    NATURE AT YOUR DOORSTEP
                                </h6>
                                <p>
                                    The ideal residential getaway for those who love nature, value
                                    tranquility and privacy, yet desire to be within close distance to
                                    many of Dubai’s world-class attractions, landmarks and transport
                                    links. Offering a true urban oasis in the heart of Dubai...
                                </p>
                                <div className="heading-box">
                                    <h5>
                                        Pent House <small>A 40*30 map</small>
                                    </h5>
                                    <h5>
                                        <small>(7 plots Left)</small> 1 Matic{" "}
                                    </h5>
                                </div>
                                <div>
                                    <a href="" className="common-btn">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-md-6">
                                <div className="project-img">
                                    <img src="assets/images/project/pj1.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="heading-main">Wilton park residences </h3>
                                <h6 className="sub-heading-inner gray-color">
                                    NATURE AT YOUR DOORSTEP
                                </h6>
                                <p>
                                    The ideal residential getaway for those who love nature, value
                                    tranquility and privacy, yet desire to be within close distance to
                                    many of Dubai’s world-class attractions, landmarks and transport
                                    links. Offering a true urban oasis in the heart of Dubai...
                                </p>
                                <div className="heading-box">
                                    <h5>
                                        Pent House <small>A 40*30 map</small>
                                    </h5>
                                    <h5>
                                        <small>(7 plots Left)</small> 1 Matic{" "}
                                    </h5>
                                </div>
                                <div>
                                    <a href="" className="common-btn">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-md-6 order-2">
                                <div className="project-img">
                                    <img src="assets/images/project/pj1.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="heading-main">Wilton park residences </h3>
                                <h6 className="sub-heading-inner gray-color">
                                    NATURE AT YOUR DOORSTEP
                                </h6>
                                <p>
                                    The ideal residential getaway for those who love nature, value
                                    tranquility and privacy, yet desire to be within close distance to
                                    many of Dubai’s world-class attractions, landmarks and transport
                                    links. Offering a true urban oasis in the heart of Dubai...
                                </p>
                                <div className="heading-box">
                                    <h5>
                                        Pent House <small>A 40*30 map</small>
                                    </h5>
                                    <h5>
                                        <small>(7 plots Left)</small> 1 Matic{" "}
                                    </h5>
                                </div>
                                <div>
                                    <a href="" className="common-btn">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PageLayout>
        </>

    )
}

export default OurProject
