import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";

function PrivateRoutes({ children }) {
	const { value: { alldata, isAuthenticated } } = useSelector((state) => state.auth);
	console.log(isAuthenticated)
	return isAuthenticated ? (
		<>
			{children}
		</>
	) : (
		<Navigate to="/login" />
	);
}

export default PrivateRoutes