import React from 'react'
import PageLayout from '../../PageLayout/PageLayout'

function ContactUs() {
    return (
        <>
            <PageLayout>
                <section className="main-banner about-banner project-sec-inner contact-us-sec">
                    <img src="assets/images/project/pj3.png" />
                    <div className="about-banner-box contact-inner-box">
                        <h2>TO DISCUSS INVESTMENT OPPORTUNITIES </h2>
                        <h6 className="sub-heading-inner">PLEASE CONTACT US:</h6>
                        <p>Phone: +971 4 278 0888</p>
                        <p>Email: info@ellingtongroup.com</p>
                        <p>
                            16th &amp; 17th Floor, Burlington Tower, Business Bay, Dubai, UAE, P.O.
                            Box 117500
                        </p>
                        <ul className="social-links">
                            <li>
                                <a href="">
                                    <img src="assets/images/social1.png" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/social2.png" />
                                </a>
                            </li>
                            <li>
                                <a href="">
                                    <img src="assets/images/social3.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </PageLayout>
        </>
    )
}

export default ContactUs