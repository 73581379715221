import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export const SuperSort = ({ field: { A_Z, Z_A, HIGH_TO_LOW, LOW_TO_HIGH, NEWEST, OLDEST }, dataSource, getSortedData, isLoading }) => {

    //Initial Data State (set only once)
    const [initialData, setInitialData] = useState(null);
    //Original Data
    const [originalData, setOriginalData] = useState([]);
    //Sorting Type
    const [sortType, setSortType] = useState("DEFAULT");


    useEffect(() => {
        //setting loading state to true
        isLoading(true);
        //Setting Initial Data once data is available (We will not change it after)
        if (initialData === null && dataSource && dataSource.length > 0) {
            setInitialData(dataSource);
        }
        //Original Data that comes from parent
        setOriginalData(dataSource);
        //Sending the Sorted Data back to parent
        //On first load we send same data back
        getSortedData(dataSource);

        //Setting loading state to false
        if (dataSource && dataSource.length > 0) {
            isLoading(false);
        }

    }, [dataSource])

    useEffect(() => {
        //ALPHABETICAL (A-Z) SORTING
        if (sortType === "A_Z" && A_Z && originalData.length > 0) {

            //Sorting logic based on user provided field
            getSortedData([...originalData].sort((a, b) => a[A_Z].localeCompare(b[A_Z])));
            return;
        }

        //ALPHABETICAL REVERSE (Z-A) SORTING
        if (sortType === "Z_A" && Z_A && originalData.length > 0) {

            //Sorting logic based on user provided field
            getSortedData([...originalData].sort((a, b) => b[Z_A].localeCompare(a[Z_A])));
            return;
        }

        //HIGH TO LOW SORTING
        if (sortType === "HIGH_TO_LOW" && HIGH_TO_LOW && originalData.length > 0) {

            //Sorting logic based on user provided number field
            getSortedData([...originalData].sort((a, b) => parseInt(b[HIGH_TO_LOW]) - parseInt(a[HIGH_TO_LOW])));
            return;
        }

        //LOW TO HIGH SORTING
        if (sortType === "LOW_TO_HIGH" && LOW_TO_HIGH && originalData.length > 0) {

            //Sorting logic based on user provided number field
            getSortedData([...originalData].sort((a, b) => parseInt(a[LOW_TO_HIGH]) - parseInt(b[LOW_TO_HIGH])));
            return;
        }

        //NEWEST SORTING
        if (sortType === "NEWEST" && NEWEST && originalData.length > 0) {

            //Sorting logic based on user provided date field
            getSortedData([...originalData].sort((a, b) => new Date(b[NEWEST]).getTime() - new Date(a[NEWEST]).getTime()));
            return;
        }

        //OLDEST SORTING
        if (sortType === "OLDEST" && OLDEST && originalData.length > 0) {

            //Sorting logic based on user provided date field
            getSortedData([...originalData].sort((a, b) => new Date(a[OLDEST]).getTime() - new Date(b[OLDEST]).getTime()));
            return;
        }

        if (sortType === "DEFAULT") {
            
            //If default case then we send back that initial data
            //that we stored on initial load
            getSortedData(initialData);
            return;
        }

    }, [sortType])

    return (
        <select
            name="super_sort"
            value={sortType}
            className="form-control bg-transparent"
            onChange={(e) => setSortType(e.target.value)}
        >

            <option value="DEFAULT">Sort Options</option>
            {A_Z && <option value="A_Z">A-Z</option>}
            {Z_A && <option value="Z_A">Z-A</option>}
            {HIGH_TO_LOW && <option value="HIGH_TO_LOW">High To Low</option>}
            {LOW_TO_HIGH && <option value="LOW_TO_HIGH">Low To High</option>}
            {NEWEST && <option value="NEWEST">Newest</option>}
            {OLDEST && <option value="OLDEST">Oldest</option>}
        </select>
    //     <select className="form-control bg-transparent">
    //     <option>Sort By</option>
    //     <option>Sort By 1</option>
    //     <option>Sort By 2</option>
    //     <option>Sort By 3</option>
    // </select>
    )
}
