import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import OurProject from './Pages/OurProject/OurProject';
import ContactUs from './Pages/ContactUs/ContactUs';
import AboutUs from './Pages/AboutUs/AboutUs';
import PropertyList from './Pages/PropertyList/PropertyList';
import Login from './Pages/Login/Login';
import "react-toastify/dist/ReactToastify.css";
import AdminHomePage from './Pages/Admin/AdminHomePage/AdminHomePage';
import AdminTransaction from './Pages/Admin/AdminTransactionList/AdminTransaction';
import AdminUserLand from './Pages/Admin/AdminUserLand/AdminUserLand';
import AdminAddLand from './Pages/Admin/AdminAddNewLand/AdminAddLand';
import PrivateRoutes from './routes/PrivateRoutes';
import Transaction from './Pages/Transaction/Transaction';
import RedirectToHome from './components/RedirectToHome';
import AdminNewHomePage from './Pages/Admin_new/Dashboard';
import AdminNewTransaction from './Pages/Admin_new/Transactions';
import AdminNewUserLand from './Pages/Admin_new/Landlist';
import AdminNewAddLand from './Pages/Admin_new/Add_Land';


function App() {
  
  
  return (
      
        <Routes>
          {/* Public Routes */}
          <Route path='/' element={<HomePage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/our-project' element={<OurProject />} />
          <Route path='/contactUs' element={<ContactUs />} />
          <Route path='/properties' element={<PropertyList />} />
          <Route path='/transaction' element={<Transaction />} />

           {/* Admin new Routes */}
           <Route path='/admin-homepage' element={<PrivateRoutes><AdminNewHomePage /></PrivateRoutes>} />
          <Route path='/admin-landlist' element={<PrivateRoutes><AdminNewUserLand /></PrivateRoutes>} />
          <Route path='/admin-transaction' element={<PrivateRoutes><AdminNewTransaction /></PrivateRoutes>} />
          <Route path='/admin-addnew-land' element={<PrivateRoutes><AdminNewAddLand /></PrivateRoutes>} />
          
          {/* Admin Routes */}
          {/* <Route path='/admin-homepage' element={<PrivateRoutes><AdminHomePage /></PrivateRoutes>} />
          <Route path='/admin-landlist' element={<PrivateRoutes><AdminUserLand /></PrivateRoutes>} />
          <Route path='/admin-transaction' element={<PrivateRoutes><AdminTransaction /></PrivateRoutes>} />
          <Route path='/admin-addnew-land' element={<PrivateRoutes><AdminAddLand /></PrivateRoutes>} /> */}

          
          
          {/* Wild card route to redirect to homepage  */}
          <Route path="*" element={<RedirectToHome/>} />
        </Routes>
      
  
  );
}

export default App;
