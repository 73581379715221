import { createSlice } from "@reduxjs/toolkit";

const user = localStorage.getItem('user');
const isAuthenticated = localStorage.getItem('isAuthenticated');
const walletAddress = localStorage.getItem("walletAddress");
const authSlice = createSlice({
  name: "Auth",
  initialState: {
    walletAddress: !!walletAddress ? walletAddress : "",
    value: { user: !!user ? JSON.parse(user) : {}, isAuthenticated: !!isAuthenticated ? JSON.parse(isAuthenticated) : false },
    // accountVerification: "",
    // routeAccess: false,
    isChangeNetwork: "",
  },
  reducers: {
    changenetwork: (state, action) => {
      console.log();
      return {
        ...state,
        isChangeNetwork: action.payload.isChangeNetwork
      }
    },
    // giveRouteAccess: (state) => {
    //   return { ...state, routeAccess: true };
    // },
    // revokeRouteAccess: (state) => {
    //   return { ...state, routeAccess: false };
    // },

    signup_data: (state, action) => {
      return {
        ...state,
        value: {
          newUser: action.payload.newUser,
        },
        // walletCreated: action.payload.walletCreated,
      };
    },

    login: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('isAuthenticated', JSON.stringify(action.payload.isAuthenticated))

      return {
        ...state,
        value: {
          user: action.payload.user,
          isAuthenticated: action.payload.isAuthenticated,
        },

      };
    },
    logout: (state) => {
      localStorage.removeItem("user");
      // window.localStorage.clear();
      localStorage.clear();
      return {
        ...state,
        walletAddress: "",
        value: { user: {}, isAuthenticated: false },
        // walletCreated: false,
      };
    },
    setWalletAddress: (state, action) => {
      localStorage.setItem("walletAddress", action.payload);
      return { ...state, walletAddress: action.payload };
    },
  },
});

export const {
  login,
  logout,
  signup_data,
  setWalletAddress,
  changenetwork

} = authSlice.actions;
export default authSlice.reducer;
