
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import axiosMain from '../../https/axios/axiosMain';
import Layout from '../../PageLayout/Admin/Layout';



const AdminDashboard = () => {

  const dispatch = useDispatch();
	const { value:{user} } = useSelector((state)=>state.auth);

	//=================Local States==================//
	const [dashboardData, setDashboardData] = useState();

	useEffect(()=>{
		getAdminHomePageData();
	},[]);

	const getAdminHomePageData = async () => {
		try {
			const url = "/dashboard"
			const payload = {
				user_id:user?._id
			}

			const res = await axiosMain.post(url, payload);
			setDashboardData(res?.data?.data)
			console.log(res.data.data)
		} catch (err) {
			console.log(err)
		}
	}


  return (
    <>
      <Layout>
        <section className="outer-dash-section">

          <Container>
            <Row>
              <Col lg={12}>
                <div className="dashboard-white-box">
                  <div className="w-100">
                    <h2 className="inner-page-heading">Dashboard</h2>
                    <Row>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div className="shadow-box-dash">
                          <p>Total Lands</p>
                          <h4>{dashboardData?.total}</h4>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div className="shadow-box-dash">
                          <p>Purchased Lands</p>


                          <h4>{dashboardData?.purchasedLand}</h4>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={6} xs={12}>
                        <div className="shadow-box-dash">
                          <p>Remaining Lands</p>


                          <h4>{dashboardData?.TotalremainingLand}</h4>

                        </div>
                      </Col>
                    </Row>


                  

                  </div>
                </div>
              </Col>

            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default AdminDashboard