import { create } from "ipfs-http-client";
var Buffer = require("buffer/").Buffer;

const projectId = '2DRQNrOlePvTOQ38b5sTnUlImiA';   // <---------- your Infura Project ID
const projectSecret = '7bf4d1c25c5a6320d5358dce713e2373';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth
  }
})
console.log(ipfs);

export const ipfsMint = async (testFile, data) => {
  try {
    ///let testFile = fs.readFileSync(`${tokenUrl}`);
    let testBuffer = new Buffer.from(testFile);

    const hash = await ipfs.add(testBuffer);

    var hash1 = hash.path;

    let testFile1 = {
      Name: `${data.name}`,
      Price: `${data.price}`,
      Description: `${data.description}`,
      image: `https://gateway.ipfs.io/ipfs/${hash1}`,
    };

    let testBuffer2 = new Buffer.from(JSON.stringify(testFile1));

    const ImgUrl = await ipfs.add(testBuffer2);
    let hash2 = ImgUrl.path;

    const contentUrl = `https://gateway.ipfs.io/ipfs/${hash2}`; //`https://gateway.ipfs.io/ipfs/${hash2}`;
    let obj = { hash, contentUrl };
    return obj;
  } catch (e) {
    console.log(e);
  }
};
