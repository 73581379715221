
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import { SuperSearch } from '../../components/SuperSearch';
import axiosMain from '../../https/axios/axiosMain';
import Layout from '../../PageLayout/Admin/Layout';


const AdminTransactions = () => {

  //===============Local States===================//
	const [transactionData, setTransactionData] = useState();
  const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		getTransactionData();
	}, [])

	//==================Functions==================//
	const getTransactionData = async () => {
		try {
			const url = '/all-transaction';
			const res = await axiosMain.post(url);
			console.log(res.data)
			setTransactionData(res?.data?.data)
		} catch (err) {
			console.log(err)
		}
	}

  return (
    <>
      <Layout>
        <section className="outer-dash-section">

          <Container>
            <Row>
              <Col lg={12}>
                <div className="dashboard-white-box">
                  <div className="w-100">
                    <h2 className="inner-page-heading mb-2">All Transactions</h2>
                   
                    <Row>
                      <Col>
                         <div className="table-box-new shadow-box-main">
                            <div className="d-flex justify-content-between outer-show-table mb-3">
                              <div className="d-flex ">
                              <span>Show Entries </span>
                                 <Form.Select aria-label="Default select example">
                                    <option>10</option>
                                    <option value="1">25</option>
                                    <option value="2">50</option>
                                    <option value="3">100</option>
                                  </Form.Select>
                              </div>
                              <div className="d-flex">
                                <span>Search </span>
                                <SuperSearch dataSource={transactionData} getfilteredData={(e)=> setFilteredData(e)} isLoading={(e)=>console.log(e)} />
                                 {/* <Form.Group className="">
                                    <Form.Control type="text" placeholder="" />
                                  </Form.Group> */}
                              </div>
                            </div>
                             <div className="table-responsive">
                             <Table  bordered >
                              <thead>
                                <tr>
                                  <th>S/No</th>
                                  <th>Land Image</th>
                                  <th>Plot Name</th>
                                  <th>From Address</th>
                                  <th>To Address</th>
                                  <th>Transaction Hash</th>
                                  <th>Token ID</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredData?.map((item, index)=>{
                                  return(
                                <tr key={index}>
                                  <td>{index}</td>
                                  <td><img
                                          className="Land-img"
                                          src={
                                            item?.image
                                          }
                                        /></td>
                                  <td>{item?.propertyName}</td>
                                  <td><p className="text-wrap">{item?.fromAddress?.slice(0,7)+"..."}</p></td>
																	<td>{item?.toAddress?.slice(0,7)+"..."}</td>
																	<td><a href={`https://testnet.bscscan.com/tx/${item?.hash}`} target="_blank" >{item?.hash.slice(0,7)+"..."}</a></td>
																	<td>{item?.tokenid}</td>
                                  <td>
                                  <button className="common-btn">
                                      Success
                                      </button>&nbsp;&nbsp;
                                      {/* <button className="bg-danger common-btn">
                                        Reject
                                      </button> */}
                                    </td>
                                </tr>

                                  )
                                })}
                                
                               
                              </tbody>
                            </Table>
                             </div>
                             <div className="d-flex justify-content-between outer-show-table d-none">
                              <div className="d-flex ">
                              {/* <span>Showing 1 to 10 of 57 entries </span> */}
                              </div>
                              <div className="d-flex">
                                  <Pagination>
                                    <Pagination.First />
                                    <Pagination.Prev />
                                    <Pagination.Item active>{1}</Pagination.Item>
                                    <Pagination.Ellipsis />

                                    
                                    <Pagination.Item >{12}</Pagination.Item>
                                    
                                    <Pagination.Ellipsis />
                                    <Pagination.Item >{20}</Pagination.Item>
                                    <Pagination.Next />
                                    <Pagination.Last />
                                  </Pagination>
                              </div>
                            </div>
                         </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </Col>

            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default AdminTransactions