import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PageLayout from '../../PageLayout/PageLayout'

function HomePage() {
    // const { walletAddress } = useSelector((state) => state.auth)
    // console.log({ walletAddress })

    //==================Local States==================//
    const [isCopied, setIsCopied] = useState(false);


    //================Functions=====================//

    //Function to copy wallet address to clipboard
    const addressCopyHandler = () => {
        console.log("copy")
        navigator.clipboard.writeText("0xB2f8d01661403Bc47930C222DBA293069695f614");
        setIsCopied(true);
    }

    return (
        <>
            <PageLayout>
                {/* <section className="main-banner">
                    <video className="banner-video"  autoplay loop="loop" muted="muted">
                        <source src={"assets/images/banner-2.mp4"} type="video/mp4" />
                    </video>
                </section> */}
                {/* <section id='video-img'>
                    <img src='assets/images/video.gif'/>
                </section> */}
                {/* <section class="showcase">
                    <div class="video-container">
                        <video src="assets/images/banner-2.mp4" autoplay muted loop></video>
                    </div>
                </section> */}
                <section id='video'>
                    <video controls="controls" muted autoPlay loop="loop" poster="assets/images/banner-img.png">
                        <source src="/assets/images/banner-2.mp4" type="video/mp4" />
                    </video>

                </section>
                <section className="partner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div>
                                    <ul className="partner-list">
                                        <li>
                                            <img src="assets/images/partner1.png" alt="logo" />
                                        </li>
                                        <li>
                                            <img src="assets/images/partner2.png" alt="logo" />
                                        </li>
                                        <li>
                                            <img src="assets/images/partner3.png" alt="logo" />
                                        </li>
                                        <li>
                                            <img src="assets/images/partner4.png" alt="logo" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div>
                                    {/* <img src="assets/images/about-img.png"> */}
                                    {/* <video
                                        className="banner2-video"
                                        width="100%"
                                        autoPlay=""
                                        loop=""
                                        muted=""
                                    >
                                        <source src={"assets/images/banner-video.mp4"} type="video/mp4" />
                                    </video> */}
                                    <video width="100%" height="100%" muted autoPlay="autoplay" loop="loop">
                                        <source src="/assets/images/banner-video.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <h3 className="heading-main">Invest In a brilliant future </h3>
                                <p>
                                    As a design-led boutique property developer, Ellington Properties
                                    values functionality as much as aesthetic. We believe in creating
                                    spaces that are in sync with residents’ lifestyles, enabling them to
                                    lead better lives, and as such, craft homes with utmost attention to
                                    every last detail.
                                    <br />
                                    <br />
                                    With the 2040 Urban Masterplan, Ellington Properties is engaging in
                                    offering higher quality properties and amenities with a focus on
                                    sustainability.
                                </p>
                                <a href="" className="common-btn">
                                    Know More
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us-sec buy-property-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h3 className="heading-main">Building Home That inspire </h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                    tristique libero a mattis interdum. Maecenas quam dolor, tempus at
                                    consequat non, tristique eget magna. Nullam luctus lacus non velit
                                    porta interdum. Fusce ullamcorper elit quis mauris auctor finibus.
                                    In tincidunt elit mauris, et hendrerit mi lacinia et. Nam at sem
                                    tellus. Duis vitae sagittis enim, sed egestas ipsum. Cras vestibulum
                                    magna velit, non ornare lacus auctor vehicula. Donec at congue
                                    libero.
                                    <br />
                                    <br />
                                    Maecenas dignissim laoreet ex, in aliquet elit laoreet ut. Phasellus
                                    eget arcu nisl. Orci varius natoque penatibus et magnis dis
                                    parturient montes, nascetur ridiculus mus. Praesent sit amet est nec
                                    libero auctor sollicitudin at in nisl. Sed vel sem et mauris
                                    tincidunt condimentum.
                                </p>
                                <a href="/properties" className="common-btn white-btn">
                                    Buy Properties{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us-sec service-sec">
                    <div className="container-fluid p-0">
                        <div className="row align-items-stretch m-0">
                            <div className="col-md-6 p-0">
                                <div>
                                    <img src="assets/images/service_img.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="service-outer">
                                    <ul className="service-list">
                                        <li>
                                            <img src="assets/images/service1.png" className="img-fluid" />
                                            <h5>Read Our verified contract</h5>
                                        </li>
                                        <li>
                                            <img src="assets/images/service2.png" className="img-fluid" />
                                            <h5>Read Our Whitepaper</h5>
                                        </li>
                                        <li>
                                            <img src="assets/images/service3.png" className="img-fluid" />
                                            <h5>Read Our First Audit</h5>
                                        </li>
                                        <li>
                                            <img src="assets/images/service4.png" className="img-fluid" />
                                            <h5>Read Our Second Audit</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contract-id-copy-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 m-auto">
                                <div className="text-center">
                                    <h3 className="heading-main">Ellington Token Contract</h3>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            readOnly
                                            defaultValue="0xB2f8d01661403Bc47930C222DBA293069695f614"
                                        />
                                        <div className="input-group-append">
                                            <button onClick={()=>addressCopyHandler()} className="common-btn" type="submit">
                                                {isCopied?"Copied":"Copy"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PageLayout>

        </>

    )
}

export default HomePage
