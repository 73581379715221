import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { buytoken } from "../../helpers/BuyNFT";
import PageLayout from "../../PageLayout/PageLayout";
import { axios } from "../../https";

function PropertyList() {
  const navigate = useNavigate();

  const {
    value: { isAuthenticated, user },
    walletAddress,
  } = useSelector((state) => state.auth);

  //======================Local States=================//
  const [propertyList, setpropertyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProperties();
  }, []);

  //=======================Functions====================//
  // Get Properties handler function
  const getProperties = async () => {
    try {
      const api = await axios.post("/property");
      console.log(api);

      setpropertyList(api.data.data);
    } catch (error) {
      if (error.response.status === 400) {
        console.log(error.response.data.message);
      } else {
        console.log("Something Went Wrong");
      }
    }
  };

  //Buy property handler
  const buyHandler = async (item) => {
    try {
      if (!walletAddress) {
        throw new Error("Please connect wallet!");
      }

      setIsLoading(item?._id);
      console.log("item", item);
      console.log("walletAddress", walletAddress);

      let token_id = item.token_id;
      token_id = parseInt(token_id);

      let adminAddress = item.address;
      console.log("adminAddress", adminAddress);

      let amount = item.min_price * Math.pow(10, 18);
      amount = amount.toFixed(0);

      const voucherObj = {
        tokenId: item.token_id,
        uri: item.token_url,
        minPrice: amount,
        signature: item.signature,
      };

      console.log("voucherObj", voucherObj);

      let buy = await buytoken(
        voucherObj,
        item.nft_id,
        item._id,
        amount,
        navigate,
        "transaction",
        adminAddress
      );
      console.log("buy", buy);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
      console.log(err);
    }
  };

  console.log("propertyList", propertyList);

  return (
    <>
      <PageLayout>
        <ToastContainer />
        <section className="main-banner about-banner project-sec-inner">
          <img src="assets/images/project/banner-img.png" />
          <div className="about-banner-box">
            <h2>WE BUILD RESIDENCES FOR PEOPLE WHO TRULY APPRECIATE DESIGN</h2>
            <h6 className="sub-heading-inner">
              FROM THE FIRST PRINCIPLES TO THE LAST DETAIL
            </h6>
          </div>
        </section>
        <section className="propert-list-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading-main mb-5 text-center">
                  Our Properties{" "}
                </h3>
              </div>
            </div>
            <div className="row">
              {propertyList?.map((value, inx) => {
                return (
                  <div className="col-md-6">
                    <div className="project-img porpert-list-box">
                      <img src={value.image} className="img-fluid" />
                      <div className="porperty-list-content">
                        <div className="inner-property">
                          <h6 className="sub-heading-inner gray-color">
                            {value.name}
                          </h6>
                          <h3 className="heading-main">{value.name}</h3>
                          <div className="heading-box">
                            <h5>
                              <small>Size</small> {value.size}{" "}
                            </h5>
                            <h5>
                              <small>Min Buying Amount</small> {value.price} {"BNB"}
                            </h5>
                            <h5>
                              <small>Price</small> {value.price} {"BNB"}
                            </h5>
                            <h5 className="d-none">
                              <small>Quantity Per brick</small> {"--- "}
                            </h5>
                            <h5 className="d-none">
                              <small>Rate per brick</small> {"--- "}
                            </h5>
                            <h5>
                              <small>Quantity</small> {value.quantity}
                            </h5>
                          </div>
                          <div>
                            {/* <a href="" className="common-btn">
                                                            Buy Now
                                                        </a> */}

                            {value.quantity == "0" ? (
                              <div className="common-btn">Sold Out</div>
                            ) : (
                              <button
                                className="common-btn"
                                onClick={() => buyHandler(value)}
                                disabled={isLoading===value?._id}
                              >
                                {isLoading===value?._id?"Processing...":"Buy Now"}
                              </button>
                            )}
                          </div>
                          {/* <div>
                            <a href="" className="view-all-images-link mb-0">
                              View Details
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="col-md-6">
                                <div className="project-img porpert-list-box">
                                    <img src="assets/images/project/pj2.png" className="img-fluid" />
                                    <div className="porperty-list-content">
                                        <div className="inner-property">
                                            <h6 className="sub-heading-inner gray-color">
                                                NATURE AT YOUR DOORSTEP
                                            </h6>
                                            <h3 className="heading-main">Wilton park residences </h3>
                                            <div className="heading-box">
                                                <h5>
                                                    <small>Size</small> A 40*30 map{" "}
                                                </h5>
                                                <h5>
                                                    <small>Min Buying Amount</small> 25 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Price</small> 50 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity Per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Rate per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity</small> 50 Plots
                                                </h5>
                                            </div>
                                            <div>
                                                <a href="" className="common-btn">
                                                    Buy Now
                                                </a>
                                            </div>
                                            <div>
                                                <a href="" className="view-all-images-link mb-0">
                                                    View Details
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="project-img porpert-list-box">
                                    <img src="assets/images/project/pj3.png" className="img-fluid" />
                                    <div className="porperty-list-content">
                                        <div className="inner-property">
                                            <h6 className="sub-heading-inner gray-color">
                                                NATURE AT YOUR DOORSTEP
                                            </h6>
                                            <h3 className="heading-main">Wilton park residences </h3>
                                            <div className="heading-box">
                                                <h5>
                                                    <small>Size</small> A 40*30 map{" "}
                                                </h5>
                                                <h5>
                                                    <small>Min Buying Amount</small> 25 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Price</small> 50 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity Per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Rate per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity</small> 50 Plots
                                                </h5>
                                            </div>
                                            <div>
                                                <a href="" className="common-btn">
                                                    Buy Now
                                                </a>
                                            </div>
                                            <div>
                                                <a href="" className="view-all-images-link mb-0">
                                                    View Details
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="project-img porpert-list-box">
                                    <img src="assets/images/project/pj1.png" className="img-fluid" />
                                    <div className="porperty-list-content">
                                        <div className="inner-property">
                                            <h6 className="sub-heading-inner gray-color">
                                                NATURE AT YOUR DOORSTEP
                                            </h6>
                                            <h3 className="heading-main">Wilton park residences </h3>
                                            <div className="heading-box">
                                                <h5>
                                                    <small>Size</small> A 40*30 map{" "}
                                                </h5>
                                                <h5>
                                                    <small>Min Buying Amount</small> 25 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Price</small> 50 Lakh{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity Per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Rate per brick</small> 1000{" "}
                                                </h5>
                                                <h5>
                                                    <small>Qunatity</small> 50 Plots
                                                </h5>
                                            </div>
                                            <div>
                                                <a href="" className="common-btn">
                                                    Buy Now
                                                </a>
                                            </div>
                                            <div>
                                                <a href="" className="view-all-images-link mb-0">
                                                    View Details
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  );
}

export default PropertyList;
