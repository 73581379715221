import React from 'react'
import PageLayout from '../../PageLayout/PageLayout'

function AboutUs() {
    return (
        <>
            <PageLayout>
                <section className="main-banner about-banner">
                    <img src="assets/images/about/banner.png" />
                    <div className="about-banner-box">
                        <h2>
                            ELLINGTON PROPERTIES, ONE OF THE LEADING REAL ESTATE COMPANIES IN DUBAI
                        </h2>
                    </div>
                </section>
                <section className="about-us-sec about-inner-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="about-img-new">
                                    <img src="assets/images/about/about-img.png" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="about-side-content">
                                    <p>
                                        Founded in 2014, Ellington Properties is Dubai’s leading
                                        design-led real estate developer and endeavours to craft beautiful
                                        Dubai properties and communities for high-quality lifestyles.
                                        <br />
                                        A truly customer-centric developer in the UAE real estate market,
                                        we develop beautiful residences in Dubai designed by incredible
                                        artistry and impeccable architecture. Our customers’ refined
                                        tastes inspire us to create compelling luxury properties that
                                        consistently transcend fashions and trends.
                                        <br />
                                        Every property is built on four key pillars which are change
                                        mindsets, establish credibility, evoke emotions and
                                        differentiation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us-sec pt-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-10 m-auto">
                                <div className=" we-build-outer text-center">
                                    <h3 className="heading-main">
                                        WE BUILD RESIDENCES FOR PEOPLE WHO TRULY APPRECIATE DESIGN FROM
                                        THE FIRST PRINCIPLES TO THE LAST DETAI{" "}
                                    </h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                                        auctor ligula sit amet neque fringilla ullamcorper. Sed non lectus
                                        ut risus sollicitudin semper. Suspendisse ligula eros, malesuada
                                        et faucibus ut, ultrices aliquam orci. Etiam magna magna, molestie
                                        et diam id, vulputate ornare nisl. Sed a nisi enim. Integer
                                        vehicula placerat risus, vitae dictum erat placerat id.
                                        Pellentesque vel risus in massa congue bibendum. Vivamus nisl
                                        nisl, feugiat quis tempor vestibulum, condimentum in orci. Ut a
                                        porta nulla, quis fringilla ipsum.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="award-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h3 className="heading-main">Awards</h3>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6">
                                            <div className="award-inner-box">
                                                <img
                                                    src="assets/images/about/award1.png"
                                                    className="img-fluid"
                                                />
                                                <h4> ARABIAN PROPERTY AWARDS</h4>
                                                <h6>2016 - 2017 </h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Nullam auctor ligula sit amet neque fringilla ullamcorper.
                                                    Sed non lectus ut risus sollicitudin semper.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="award-inner-box">
                                                <img
                                                    src="assets/images/about/award2.png"
                                                    className="img-fluid"
                                                />
                                                <h4> ARABIAN PROPERTY AWARDS</h4>
                                                <h6>2016 - 2017 </h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Nullam auctor ligula sit amet neque fringilla ullamcorper.
                                                    Sed non lectus ut risus sollicitudin semper.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="award-inner-box">
                                                <img
                                                    src="assets/images/about/award3.png"
                                                    className="img-fluid"
                                                />
                                                <h4> ARABIAN PROPERTY AWARDS</h4>
                                                <h6>2016 - 2017 </h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Nullam auctor ligula sit amet neque fringilla ullamcorper.
                                                    Sed non lectus ut risus sollicitudin semper.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="award-inner-box">
                                                <img
                                                    src="assets/images/about/award4.png"
                                                    className="img-fluid"
                                                />
                                                <h4> ARABIAN PROPERTY AWARDS</h4>
                                                <h6>2016 - 2017 </h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Nullam auctor ligula sit amet neque fringilla ullamcorper.
                                                    Sed non lectus ut risus sollicitudin semper.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PageLayout>
        </>
    )
}

export default AboutUs