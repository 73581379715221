import axiosMain from "../https/axios/axiosMain";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	transactions: [],
	error: ''
}


const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		//normal reducers
	},
	extraReducers: (builder) => {


		//Fetch Transaction Thunk
		builder.addCase(fetchTransactions.pending, state => {
			state.isLoading = true
		})
		builder.addCase(fetchTransactions.fulfilled, (state, action) => {
			state.isLoading = false;
			state.transactions = action.payload;
			state.error = "";
		})
		builder.addCase(fetchTransactions.rejected, (state, action) => {
			console.log(action)
			state.isLoading = false;
			state.transactions = [];
			state.error = action.error.message;
		})
	}
})
export default userSlice.reducer;



//Thunks

//async thunk to fetch transactions
export const fetchTransactions = createAsyncThunk("user/transactions", (address) => {
	const payload = {
		wallet: address
	}
	return axiosMain.post("/transaction", payload).then(response => response.data.data);
});


