import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table, Form, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import axiosMain from "../../https/axios/axiosMain";
import Layout from "../../PageLayout/Admin/Layout";
import { SuperSearch } from "../../components/SuperSearch";
import { toast } from "react-toastify";

const AdminLandList = () => {
  const { user } = useSelector((state) => state.auth.value);

  //===============Local States================//
  const [landList, setLandList] = useState();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (user._id) {
      getUserLandList();
    }
  }, [user]);

  //==================Functions===================//
  //To get land list 
  const getUserLandList = async () => {
    try {
      const url = "/lands-list";
      const payload = {
        user_id: user?._id,
      };
      const res = await axiosMain.post(url, payload);
      console.log(res.data.data);
      setLandList(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };


  //To remove/delete a land
  const deleteLandHandler = async (landId) => {
    try {
      const url = "/delete-land";
      const payload = {
        user_id:user?._id,
        landId
      }
      const res = await axiosMain.post(url, payload);
      setLandList((prev)=>prev.filter((item)=>item._id !== landId))
      if(res.data.status){
        toast.success("Land Deleted Successfully.")
      }
      console.log(res.data.data);
    } catch (err) {
      console.log(err)
    }
  }



  return (
    <>
      <Layout>
        <section className="outer-dash-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="dashboard-white-box">
                  <div className="w-100">
                    <h2 className="inner-page-heading mb-2">LandList</h2>

                    <Row>
                      <Col>
                        <div className="table-box-new shadow-box-main">
                          <div className="d-flex justify-content-between outer-show-table mb-3">
                            <div className="d-flex ">
                              <span>Show Entries </span>
                              <Form.Select aria-label="Default select example">
                                <option>10</option>
                                <option value="1">25</option>
                                <option value="2">50</option>
                                <option value="3">100</option>
                              </Form.Select>
                            </div>
                            <div className="d-flex">
                              <span>Search </span>
                              <SuperSearch
                                dataSource={landList}
                                getfilteredData={(e) => setFilteredData(e)}
                                isLoading={(e) => console.log(e)}
                              />
                              {/* <Form.Group className="">
                                    <Form.Control type="text" placeholder="" />
                                  </Form.Group> */}
                            </div>
                          </div>
                          <div className="table-responsive">
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th width="10%">Land Image</th>
                                  <th width="10%">Plot Name</th>
                                  <th width="10%">No. of Plots</th>
                                  <th width="10%">Plot Size </th>
                                  <th width="10%">Price </th>
                                  <th width="30%">Description</th>
                                  <th width="20%">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredData?.map((item, index) => {
                                  return (
                                    <tr key={item?._id}>
                                      <td>
                                        <img
                                          className="Land-img"
                                          src={
                                            item?.image ||
                                            "assets/images/about-img.png"
                                          }
                                        />
                                      </td>
                                      <td>{item?.name}</td>
                                      <td>{item?.quantity}</td>
                                      <td>{item?.size}</td>
                                      <td>{item?.price}</td>
                                      <td>{item?.content}</td>
                                      <td>
                                        {/* <button className="common-btn">
                                          <i
                                            className="fa fa-pencil-square"
                                            aria-hidden="true"
                                            aria-setsize="large"
                                          ></i>
                                        </button> */}
                                        &nbsp;&nbsp;
                                        <button className="bg-danger common-btn " onClick={()=>deleteLandHandler(item?._id)} >
                                          <i
                                            className="fa fa-window-close"
                                            aria-hidden="true"
                                            aria-setsize="large"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                          <div className="d-flex justify-content-between outer-show-table d-none">
                            <div className="d-flex ">
                              {/* <span>Showing 1 to 10 of 57 entries </span> */}
                            </div>
                            <div className="d-flex">
                              <Pagination>
                                <Pagination.First />
                                <Pagination.Prev />
                                <Pagination.Item active>{1}</Pagination.Item>
                                <Pagination.Ellipsis />

                                <Pagination.Item>{12}</Pagination.Item>

                                <Pagination.Ellipsis />
                                <Pagination.Item>{20}</Pagination.Item>
                                <Pagination.Next />
                                <Pagination.Last />
                              </Pagination>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default AdminLandList;
