import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AdminHeader from '../UI/AdminHeader/AdminHeader';
import axiosMain from '../../../https/axios/axiosMain';

function AdminHomePage() {

	const dispatch = useDispatch();
	const { value:{user} } = useSelector((state)=>state.auth);

	//=================Local States==================//
	const [dashboardData, setDashboardData] = useState();

	useEffect(()=>{
		getAdminHomePageData();
	},[]);

	const getAdminHomePageData = async () => {
		try {
			const url = "/dashboard"
			const payload = {
				user_id:user?._id
			}

			const res = await axiosMain.post(url, payload);
			setDashboardData(res?.data?.data)
			console.log(res.data.data)
		} catch (err) {
			console.log(err)
		}
	}



	return (
		<>
			<AdminHeader>
				<section id="dash-box">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-sm-6">
								<div className="card overflow-hidden">
									<div className="card-header align-items-start border-0 pb-0">
										<div className="mr-auto">
											<p className="mb-2 fs-13">
												<i
													className="fa fa-caret-up scale5 mr-2 text-success"
													aria-hidden="true"
												/>
												Total Lands
											</p>
											<h2 className="text-black mb-0 font-w600">{dashboardData?.total}</h2>
										</div>
									</div>
									<div className="card-body p-0">
										<div className="chartjs-size-monitor">
											<div className="chartjs-size-monitor-expand">
												<div className="" />
											</div>
											<div className="chartjs-size-monitor-shrink">
												<div className="" />
											</div>
										</div>
										<canvas
											id="widgetChart"
											className="max-h80 mt-auto chartjs-render-monitor"
											style={{ display: "block", width: 279, height: 80 }}
											width={279}
											height={80}
										/>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="card overflow-hidden">
									<div className="card-header align-items-start border-0 pb-0">
										<div className="mr-auto">
											<p className="mb-2 fs-13">
												<i
													className="fa fa-caret-down scale5 mr-2 text-danger"
													aria-hidden="true"
												/>
												Purchased Lands
											</p>
											<h2 className="text-black mb-0 font-w600">{dashboardData?.purchasedLand}</h2>
										</div>
									</div>
									<div className="card-body p-0">
										<div className="chartjs-size-monitor">
											<div className="chartjs-size-monitor-expand">
												<div className="" />
											</div>
											<div className="chartjs-size-monitor-shrink">
												<div className="" />
											</div>
										</div>
										<canvas
											id="widgetChart2"
											className="max-h80 mt-auto chartjs-render-monitor"
											width={279}
											height={80}
											style={{ display: "block", width: 279, height: 80 }}
										/>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="card overflow-hidden">
									<div className="card-header align-items-start border-0 pb-0">
										<div className="mr-auto">
											<p className="mb-2 fs-13">
												<i
													className="fa fa-caret-down scale5 mr-2 text-danger"
													aria-hidden="true"
												/>
												Remaining Lands
											</p>
											<h2 className="text-black mb-0 font-w600">{dashboardData?.TotalremainingLand}</h2>
										</div>
									</div>
									<div className="card-body p-0">
										<div className="chartjs-size-monitor">
											<div className="chartjs-size-monitor-expand">
												<div className="" />
											</div>
											<div className="chartjs-size-monitor-shrink">
												<div className="" />
											</div>
										</div>
										<canvas
											id="widgetChart3"
											className="max-h80 mt-auto chartjs-render-monitor"
											width={279}
											height={80}
											style={{ display: "block", width: 279, height: 80 }}
										/>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 d-none">
								<div className="card overflow-hidden">
									<div className="card-header align-items-start border-0 pb-0">
										<div className="mr-auto">
											<p className="mb-2 fs-13">
												<i
													className="fa fa-caret-up scale5 mr-2 text-success"
													aria-hidden="true"
												/>
												All
											</p>
											<h2 className="text-black mb-0 font-w600">35,123</h2>
										</div>
									</div>
									<div className="card-body p-0">
										<div className="chartjs-size-monitor">
											<div className="chartjs-size-monitor-expand">
												<div className="" />
											</div>
											<div className="chartjs-size-monitor-shrink">
												<div className="" />
											</div>
										</div>
										<canvas
											id="widgetChart4"
											className="max-h80 mt-auto chartjs-render-monitor"
											width={279}
											height={80}
											style={{ display: "block", width: 279, height: 80 }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>


			</AdminHeader>
		</>
	)
}

export default AdminHomePage;