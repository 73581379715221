import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import AdminHeader from '../UI/AdminHeader/AdminHeader';
import { useSelector } from 'react-redux';
import axiosMain from '../../../https/axios/axiosMain';

function AdminUserLand() {

	const { user } = useSelector((state) => state.auth.value);

	//===============Local States================//
	const [landList, setLandList] = useState();

	useEffect(() => {
		if (user._id) {
			getUserLandList();
		}
	}, [user]);


	//==================Functions===================//
	const getUserLandList = async () => {
		try {
			const url = "/lands-list";
			const payload = {
				user_id: user?._id
			}
			const res = await axiosMain.post(url, payload);
			console.log(res.data.data);
			setLandList(res?.data?.data);

		} catch (err) {
			console.log(err);
		}
	}

	return (
		<>
			<AdminHeader>
				<section className='container' id='landlist'>
					<div className='row'>
						<div className="col-md-12">
							<div className="card">
								<div className='d-flex justify-content-between'>
									<div className='d-flex mt-4 align-items-center'>
										<div>Show</div>
										<div>
											<DropdownButton id="dropdown-basic-button" title="0">
												<Dropdown.Item href="#/action-1">10</Dropdown.Item>
												<Dropdown.Item href="#/action-2">20</Dropdown.Item>
												<Dropdown.Item href="#/action-3">30</Dropdown.Item>
											</DropdownButton>
										</div>
										<div>entries</div>
									</div>
									<div>
										<Form.Control type="text" placeholder="Search" className='mt-4' />
									</div>
								</div>
								<div className='mt-4'>
									<span className="mb-2 text-muted">Transaction List</span>
									<hr />
									<div className="table-responsive">
										<Table striped bordered hover>
											<thead>
												<tr>
													<th>Land Image</th>
													{/* <th>Video</th> */}
													<th>Plot Name</th>
													<th>Number of Plots</th>
													<th>Plot Size</th>
													<th>Price</th>
													<th>Description</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody className='adminUserLand'>
												{landList?.map((item, index) => {
													return (
														<tr key={item?._id}>
															<td><img src={item?.image || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWE6ROuyKb_t8qxw93sHqyF0-kDGqy1dC_PiQz6fTFBA&s'} /></td>
															{/* <td>Mark</td> */}
															<td>{item?.name}</td>
															<td>{item?.quantity}</td>
															<td>{item?.size}</td>
															<td>{item?.price}</td>
															<td>{item?.content}</td>
															<td>
																<button className='btn bg-primary'><i class="fa fa-pencil-square" aria-hidden="true" aria-setsize="large"></i></button>&nbsp;&nbsp;
																<button className='btn bg-danger'><i class="fa fa-window-close" aria-hidden="true" aria-setsize="large"></i></button>
															</td>
														</tr>
													)
												})}
											</tbody>
										</Table>
									</div>
								</div>
								<span>Showing 1 to {landList?.length} of {landList?.length} entries</span>
							</div>
						</div>
					</div>
				</section>
			</AdminHeader>
		</>
	)
}

export default AdminUserLand