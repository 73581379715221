import { configureStore } from "@reduxjs/toolkit";
import authReducers from "../reducers/authReducers";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
// import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import {persistReducer, persistStore} from "redux-persist";


const rootReducer = combineReducers({
  auth: authReducers,
  user: userReducer
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools:process.env.NODE_ENV !=="production"
});

export default store;
export const persistor = persistStore(store);
