import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Col, Container, Row, Table, Form, InputGroup, FormControl } from "react-bootstrap";
import Layout from "../../PageLayout/Admin/Layout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ipfsMint } from "../../helpers/ipfs";
import { Mint } from "../../helpers/Mint";
import { axios } from "../../https";
import { toast } from "react-toastify";

const AdminAddLand = () => {
  const navigate = useNavigate();

  const {
    value: { isAuthenticated, user },
    walletAddress,
  } = useSelector((state) => state.auth);

  //=================Local States===================//
  const [tokenIdsUsed, setTokenIdsUsed] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [contentImage, setContentImage] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    file: "",
    price: "",
    category: "",
    supply: "",
    description: "",
  });
  console.log({ photoUrl });
  console.log({ imageUrl });
  console.log({ walletAddress });

  useEffect(() => {
    getTokenUsedCount();
  }, []);

  //======================Validations===================//
  const validationSchema = Yup.object({
    name: Yup.string().required("Plot Name is required!"),
    size: Yup.string().required("Plot Size is required!"),
    price: Yup.string().required("Plot Price is required!"),
    quantity: Yup.string().required("Plot Quantity is required!"),
    content: Yup.string().required("Plot Description is required!"),
    image: Yup.string().required("Plot Image is required!"),
  });

  console.log("user", user);

  //===================Functions======================//
  const handleFile = (e) => {
    const { files } = e.target;

    const reader = new window.FileReader();
    reader.readAsArrayBuffer(files[0]);

    reader.onloadend = () => {
      setContentImage(reader.result);
    };

    if (files[0]) {
      if (files[0].type.includes("image")) {
        const filename = files[0].name;
        const fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
        if (
          fileExtension.toLowerCase() === "png" ||
          fileExtension.toLowerCase() === "jpg" ||
          fileExtension.toLowerCase() === "gif" ||
          fileExtension.toLowerCase() === "jpeg"
        ) {
          setFormData({
            ...formData,
            file: e.currentTarget.files[0],
          });
          setPhotoUrl(URL.createObjectURL(files[0]));
        }
      }
    }
  };

  const submitBtnHandler = async (values) => {
    try {
      setIsLoading(true);
      // console.log(values);
      // console.log("formData", formData);
      // console.log("contentImage", contentImage);
      // console.log(values.image);
      const DataObj = {
        name: values.name,
        quantity: values.quantity,
        size: values.size,
        price: values.price,
        content: values.content,
      };

      const hash = await ipfsMint(contentImage, DataObj);
      console.log("hash", hash);

      const img_url = hash.hash.path;
      const state = hash.contentUrl.replace(
        "https://gateway.ipfs.io/ipfs/",
        ""
      );

      const lazy = await Mint(state, values.price, tokenIdsUsed);
      console.log("lazy", lazy);
      console.log({ img_url });

      const newData = new FormData();
      newData.append("user_id", user._id);
      newData.append("name", values.name);
      newData.append("price", values.price);
      newData.append("size", values.size);
      newData.append("quantity", values.quantity);
      newData.append("content", values.content);
      newData.append("img_url", state);
      newData.append("file", values.image);

      newData.append("signature", lazy.voucher.signature);
      newData.append("token_id", tokenIdsUsed);
      newData.append("address", lazy.address);
      console.log("file", values.image);
      if (lazy) {
        console.log({ newData });
        const response = await axios.post("/add-new-land", newData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        if (response.data.status) {
          toast.success("Property added successfully", {
            position: "top-center",
          });

          setTimeout(() => {
            navigate("/admin-landlist");
          }, 3000);
        }
      } else {
        toast.error("Transaction Reverted", {
          position: "top-center",
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const clearImage = () => {
    setImageUrl([]);
  };

  const connectWallet = () => {
    console.log("connect wallet")
    toast("Please Connect Wallet");
  };

  //To get token used count
  const getTokenUsedCount = async () => {
    try {
      const url = "/get-token-count";
      const res = await axios.get(url);
      setTokenIdsUsed(res?.data?.tokenIdsUsed);
      // console.log(res.data)
    } catch (err) {
      console.log(err);
      // toast.error(err.message)
    }
  };

  return (
    <>
      <Layout>
        <section className="outer-dash-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="dashboard-white-box">
                  <div className="w-100">
                    <h2 className="inner-page-heading">Add New Land</h2>
                    <div className="inner-dash-form shadow-box-main">
                      <Formik
                        initialValues={{
                          name: "",
                          size: "",
                          price: "",
                          quantity: "",
                          content: "",
                          image: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          submitBtnHandler(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          // isSubmitting,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col lg={4}>
                              <div class="Land-img">
                                <img
                                  src={imageUrl[0]}
                                  // src="https://cdn.pixabay.com/photo/2017/08/06/21/01/louvre-2596278_960_720.jpg"
                                  id="output"
                                  width="200"
                                />
                              </div>
                              <div className="text-center mt-3">
                                <button className="common-btn upload-land-btn m-2">
                                  Upload
                                  <Form.Control
                                    type="file"
                                    id="imgInp6"
                                    defaultValue=""
                                    name="file"
                                    accept="image/*"
                                    // onchange="readURL(this);"

                                    onChange={(e) => {
                                      const { files } = e.target;
                                      if (files) {
                                        const fileArray = Array.from(
                                          files
                                        ).filter((file) =>
                                          file.type.includes("image")
                                        );
                                        const fileArrayBlob = fileArray.map(
                                          (file) => URL.createObjectURL(file)
                                        );
                                        setFieldValue("image", fileArray[0]);
                                        setImageUrl([...fileArrayBlob]);
                                      }
                                      handleFile(e);
                                    }}
                                    required=""
                                  />
                                </button>
                                <button
                                  className="common-btn m-2"
                                  onClick={clearImage}
                                >
                                  Remove
                                </button>
                              </div>
                              <div className="error-message">
                                {errors.image && touched.image && errors.image}
                              </div>
                            </Col>
                            <Col lg={8}>
                              <Row>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Plot name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      placeholder="Plot Name"
                                      value={values.name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <div className="error-message">
                                    {errors.name && touched.name && errors.name}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Number of plots</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="quantity"
                                      placeholder="Number of plots"
                                      value={values.quantity}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <div className="error-message">
                                    {errors.quantity &&
                                      touched.quantity &&
                                      errors.quantity}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Plot Size</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="size"
                                      placeholder="Plot Size"
                                      value={values.size}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <div className="error-message">
                                    {errors.size && touched.size && errors.size}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Plot Price</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="price"
                                      placeholder="Plot Price"
                                      value={values.price}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <div className="error-message">
                                    {errors.price &&
                                      touched.price &&
                                      errors.price}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      name="content"
                                      rows={3}
                                      placeholder="Plot Description"
                                      value={values.content}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Form.Group>
                                  <div className="error-message">
                                    {errors.content &&
                                      touched.content &&
                                      errors.content}
                                  </div>
                                </Col>
                              </Row>

                              <div>
                                {
                                  walletAddress ?
                                  <button className="common-btn  m-0" type="submit" disabled={isLoading}  >
                                  {isLoading?"Processing...":"Submit"}
                                </button>
                                  :
                                <button className="common-btn  m-0" type="button" onClick={connectWallet}>
                                  Submit
                                </button>
                                }
                              </div>
                            </Col>
                          </Row>
                          </Form>
                        )}
                      </Formik>

                      <div></div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default AdminAddLand;
